import {
    Component,
    ChangeDetectionStrategy,
    Input, HostBinding
} from '@angular/core';

@Component({
  selector: 'age-range-button',
  template: `<div class="age age_{{_age}} {{size}}">{{selfDeclared ? 'A' : ''}}{{ _age }}</div>`,
  styleUrls: ['./age-range-button.component.scss']
})
export class AgeRangeButtonComponent {
    _age: string;

    @Input()
    set age(age: string) {
        const split = age.toLowerCase().split(' ');
        this._age = split.length > 1 ? split[0] : split[0][0];
    }
    @Input() size: '' | 'small' | 'big' = '';
    @Input() selfDeclared: boolean;

    @Input()
    @HostBinding('class.checked') checked = false;
}
